<template>
  <div class="packing">
    <el-card>
      <div slot="header" class="clearfix">
<!--        <div style="margin-bottom:20px;">-->
<!--          拣货-手机版-->
<!--          <el-button @click="pushToPhonePage" size="mini">切换电脑版</el-button>-->
<!--        </div>-->
        <div style="margin-bottom:20px;">
          拣货
          <el-button @click="pushToPhonePage" size="mini">切换手机版</el-button>
        </div>
        <el-form :inline="true" id="el-form" ref="form" :model="form" class="demo-form-inline" size="mini">
          <el-form-item label="快递单号" prop="mailNo">
            <el-input v-model="form.mailNo" clearable :disabled="input1Disabled"  @keyup.native.enter="nextFocus($event)"></el-input>
          </el-form-item>
          <el-form-item label="商品编码" prop="mailNo">
            <el-input v-model="goodsCode" clearable :disabled="input2Disabled||!form.mailNo" @keyup.native.enter="nextFocus($event)"></el-input>
          </el-form-item>
          <el-form-item class="btn-right">
            <el-button size="mini" type="success" @click="onSubmit" :disabled="!form.mailNo||goodsList.length==0" >提交</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div>
        <el-table
          :data="goodsList"
          border
          style="width: 100%" size="small">
          <el-table-column prop="goodsCode" label="商品编码"></el-table-column>
          <el-table-column prop="name" label="商品"></el-table-column>
          <el-table-column prop="changeCount" label="商品数量"></el-table-column>
        </el-table>
      </div>
      <div v-if="dialogVisible">
        <el-dialog
          title="拣货商品"
          :visible.sync="dialogVisible"
          width="80%"
          @close="onCancel()">
          <div>
            <div>
              <el-table
                :data="form.goodsList"
                border
                style="width: 100%" size="small">
                <el-table-column prop="goodsCode" label="商品编码"></el-table-column>
                <el-table-column prop="name" label="商品"></el-table-column>
                <el-table-column prop="changeCount" label="商品数量">
                  <template slot-scope="scope">
                    <div>
                      <el-input
                        v-model="scope.row.changeCount"
                        placeholder="请输入"
                      ></el-input>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div style="margin-top:20px">
              <el-form :model="form" label-width="80px" size="small" class="demo-form-inline">
                <el-form-item label="快递单号">
                  <el-input v-model="form.mailNo" disabled></el-input>
                </el-form-item>
                <el-form-item class="btn-right">
                  <el-button type="danger" @click="onCancel()" >取消</el-button>
                  <el-button type="primary" @click="onSure()" >确定</el-button>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </el-dialog>
      </div>
    </el-card>

  </div>
</template>

<script>
import {getUserInfo} from '@/services/user'
import {getGoodsByCode} from "@/services/product";
import Speech from 'speak-tts'
import {packing} from "@/services/logistics";
export default {
  name: 'packing',
  data() {
    return {
      goodsCode:'',
      formGoods:{},
      userInfo: {},
      // 用于存储资源列表数据
      form: {
        goodsList:[]
      },
      dialogVisible: false,
      goodsList: [],
      input1Disabled: false,
      input2Disabled: true,
    }
  },
  methods: {
    pushToPhonePage() {
      this.$router.push('/phone/packing');
    },
    // pushToPhonePage() {
    //   this.$router.push('/packing');
    // },
    initInputDOM() {
      //获取id为el-form下的所有input 框
      const inputDoms = document.querySelectorAll(
        "#el-form .el-input__inner"
      );
      //遍历这个input框给他们一个标识
      inputDoms.forEach((item, index) => {
        item.setAttribute("data-index", index);
      });
      this.inputDoms = inputDoms;
      setTimeout(() => {
        // 需要等待的操作
        this.inputDoms[0].focus()
      }, 100)

    },
    //回车事件
    nextFocus(event) {
      const index = event.target.getAttribute("data-index");
      const nextIndex = parseInt(index) + 1;
      const length = this.inputDoms.length;
      if (nextIndex < length) {
        this.input1Disabled = true;
        this.input2Disabled = false;
        setTimeout(() => {
          // 需要等待的操作
          this.inputDoms[nextIndex].focus();
        }, 100)
      } else {
        this.handleInput2Enter()
        this.inputDoms[1].focus()
      }
    },
    async handleInput2Enter() {
      let goodsCode = this.goodsCode
      // 调用远程接口并添加数据到集合
      if (goodsCode) {
        try {
          const { data } = await getGoodsByCode(goodsCode); // 假设这是一个异步函数获取商品信息
          if (data.code === 200) {
            const goodsName = `${data.data.goodsName}|${data.data.colourName}|${data.data.specsName}`;
            const item = {'goodsCode':goodsCode,'name':goodsName,'changeCount':1}
            this.goodsList.push(item)
          }
        } catch (error) {
          console.error(error);
        }
      }
      this.goodsCode = '';  // 清空输入框
    },

    // 上架
    async onSubmit() {
      const newCollection = {};
      // 遍历原始集合
      this.goodsList.forEach(item => {
        const { goodsCode, name,changeCount } = item;

        if (newCollection[goodsCode]) {
          // 如果已经存在相同的元素，将数据相加
          newCollection[goodsCode].changeCount += changeCount;
        } else {
          // 如果元素是第一次出现，创建新的对象
          newCollection[goodsCode] = { goodsCode, name,changeCount };
        }
      });
      this.form.goodsList = Object.values(newCollection)
      if (this.form.goodsList.length<=0) {
        return
      }
      this.dialogVisible = true
    },
    // 弹框取消按钮
    onCancel(){
      this.dialogVisible = false
      this.speak('取消')
      this.reset()
    },
    async onSure() {
      this.form.packingName = this.userInfo.userName
      this.form.packingPhone = this.userInfo.phone
      const {data} = await packing(this.form);
      if (200 === data.code) {
        this.speak("操作成功")
        this.reset()
        this.dialogVisible = false
      } else {
        this.$message.error(data.message)
        this.speak(data.message)
      }
    },

    async loadUserInfo() {
      const {data} = await getUserInfo()
      if (data.code === 200) {
        return this.userInfo = data.data
      }
      this.$message.error('获取用户信息失败')
    },

    // 重置上传单号和重量
    reset() {
      this.input1Disabled = false;
      this.input2Disabled = true;
      this.form = {}
      this.goodsList = []
      setTimeout(() => {
        // 需要等待的操作
        this.inputDoms[0].focus()
      }, 100)

    },

    speechInit(){
      this.speech = new Speech();
      this.speech.setLanguage('zh-CN');
      this.speech.init().then(()=>{
        console.log('语音播报初始化完成...')
      })
    },

    //语音播报
    speak(msg){
      this.speech.speak({text:msg}).then(()=>{
        console.log("播报完成...")
      })
    },
  },
  mounted() {
    this.speechInit();
  },
  created() {
    this.$nextTick(() => {
      this.initInputDOM();
    })
    this.loadUserInfo()
  },
  filters: {

  }
}
</script>

<style lang="scss" scoped>
.statistic{
  margin: 10px;
}
</style>
